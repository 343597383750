<template>
	<div>
        <s-toolbar label="Reporte Pesadas" dark color="#8e8f91" excel @excel="exportExcel()"></s-toolbar>
		<v-card>
            <v-container>
                <v-row>
                    <v-col cols="12" lg="3" md="3">
                        <s-date label="Fecha Inicio" v-model="cDateInitial" @input="loadReportHeavy()"> </s-date>
                    </v-col>
                    <v-col cols="12" lg="3" md="3">
                        <s-date label="Fecha Fin" v-model="cDateFin" @input="loadReportHeavy()"> </s-date>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <s-toolbar label="Listado" dark color="#0055ff"></s-toolbar>
                        <v-card dense>
                            <v-data-table
                                item-key="WpbID"
                                dense
                                :items="itemsHeavy"
                                :headers="headersHeavy"
                                :items-per-page="-1"
                                hide-default-footer
                                group-by="RptNumberHarvestGuide"
                                disable-sort
                                
                            > 
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
		</v-card>
	</div>
</template>
<script>
    import _sReceptionFresh from "../../../services/FreshProduction/ReceptionFreshService";
	export default {
		components: {},
		data: () => ({

            itemsHeavy: [],
            headersHeavy: [
                { text: "N° Pallet",            value: "RptNumberPalletsem",    width: 80   },
                { text: "Semana",               value: "RptWeek",               width: 80   },
                { text: "Fecha",                value: "RptDate",               width: 80   },
                { text: "Guia Cosecha",         value: "RptNumberHarvestGuide", width: 130  },
                { text: "Productor",            value: "PrdCardName",           width: 180  },
                { text: "Fundo/Lote",           value: "FltName",               width: 180  },
                { text: "Tipo Cultivo",         value: "TypeCropName",          width: 80   },
                { text: "Variedad",             value: "VrtName",               width: 80   },
                { text: "Peso Jaba",            value: "WpbWeightJaba",         width: 80   },
                { text: "Jabas",                value: "WpbJabasQuantity",      width: 80   },
                { text: "Peso Bruto",           value: "WpbWeightGross",        width: 80   },
                { text: "Destare de Jabas",     value: "WpbDestareJaba",        width: 80   },
                { text: "Promedio de Jaba",     value: "Promedio_Jaba",         width: 80   },
                { text: "Peso Neto",            value: "WpbWeightNet",          width: 80   },

            ],
            cDateInitial: null, 
            cDateFin : null,
        }),

        created(){
            this.cDateInitial = this.$fun.getDate();
            this.cDateFin = this.$fun.getDate();
            console.log(this.$fun.getDate());
            this.loadReportHeavy();
        },

		methods: {

            loadReportHeavy(){
                 _sReceptionFresh.GetReportHeavy({cDateInitial: this.cDateInitial, cDateFin: this.cDateFin, TypeDestiny: 1}, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200){
                        this.itemsHeavy = r.data;
                        this.itemsHeavy.forEach(element => {
                            element.RptDate = this.$moment(element.RptDate).format(this.$const.FormatDateDB);
                        });
                    }
                    
                });
            },

            exportExcel(){
                _sReceptionFresh.exportExcel(this.itemsHeavy, this.$fun.getUserID()).then((r) => {
                    if(r.status == 200){
                        this.$fun.downloadFile(r.data, this.$const.TypeFile.EXCEL, this.$fun.getUserID());
                    }
                    
                });
            },

        },
	};
</script>
